<template>
  <div>
    <b-overlay :show="loading">
      <b-card>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <h5 class="font-weight-bold">
                Ficha
              </h5>
              <b-form-input
                id="ficha"
                v-model="filter.ficha"
                placeholder="N° de Ficha"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <h5 class="font-weight-bold">
                Orden de Compra
              </h5>
              <b-form-input
                id="ordenCompra"
                v-model="filter.ordenCompra"
                placeholder="N° de Orden de Compra"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <h5 class="font-weight-bold">
                Fecha de Registro
              </h5>
              <div class="input-group">
                <flat-pickr
                  v-model="filter.fecha"
                  class="form-control"
                  placeholder="Click para seleccionar..."
                  :config="config"
                  reset-button
                />
                <div class="input-group-append">
                  <button
                    class="btn-clear"
                    type="button"
                    title="Limpiar"
                    data-clear
                  >
                    <feather-icon
                      icon="XIcon"
                    />
                  </button>
                </div>
              </div>
            </b-form-group>
          </b-col>
          <b-col>
            <div class="text-right">
              <b-button

                variant="outline-primary"
                type="submit"
                @click="buscar_listado"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span class="align-middle">Buscar</span>
              </b-button>

            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <h4 class="card-title">
          Listado Rectificaciones
        </h4>
        <b-row class="pr-1 pl-1">
          <b-col
            sm="12"
            md="6"
            class="my-1 text-right"
          >
            <div class="d-flex align-items-center mb-1 mb-md-0">
              <label>Mostrar</label>
              <b-form-select
                id="perPageSelect1"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                class="mx-50 col-md-2"
                @change="onPerPage"
              />
              <label>filas por página</label>
            </div>
          </b-col>
        </b-row>
        <b-col
          v-if="showTable"
          cols="12"
        >
          <b-table
            id="my-table"
            ref="table"
            striped
            hover
            small
            :per-page="perPage"
            :items="listado"
            :fields="filteredHeaders"
            responsive
            class="mb-0"
            show-empty
          >
            <template #cell(doc)="data">
              <b-link
                v-b-modal.modal-lg
                class="font-weight-bold"
                modal-motivo
                @click="getDocRectificaciones(data.item)"
              >
                {{ data.item.doc }}</b-link>
            </template>
            <template #cell(acciones)="data">
              <div class="text-center">
                <b-button
                  :id="`detaill-acction-${data.item.id}`"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="getDetalleRectificacion(data.item.id_rectificacion)"
                  v-b-tooltip.hover.v-primary title="Detalle de Rectificación"
                >
                  <feather-icon
                    icon="EyeIcon"
                  />
                </b-button>
              </div>
              <div
                v-if="data.item.motivo"
                class="text-center"
              >
                <b-button
                  id="tooltip-motivo"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-motivo
                  variant="flat-primary"
                  class="btn-icon"
                  @click="getMotivoRechazo(data.item)"
                  v-b-tooltip.hover.v-primary title="Motivo de Rechazo"
                >
                  <feather-icon icon="FileTextIcon" />
                </b-button>

              </div>

            </template>
            <template #empty="scope">
              <div class="text-center">
                No existen resultados
              </div>
            </template>
          </b-table>
        </b-col>

        <b-row class="pl-1">
          <b-col
            cols="8"
            class="mt-3"
          >
            <div v-if="totalRows > 0">
              <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
            </div>
          </b-col>

          <b-col cols="4">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :value="page"
              :per-page="perPage"
              align="right"
              class="mt-3 mr-1"
              aria-controls="my-table"
              @change="onPageChange"
            />
          </b-col>

        </b-row>
        <b-modal
          id="modal-lg"
          ok-only
          ok-title="Cerrar"
          centered
          size="lg"
          title="Documentos Adjuntos Rectificación"
          @ok="getList"
        >
          <div>
            <b-row>
              <b-col md="9">
                <b-form-file
                        ref="file-input"
                        v-model="file"
                        class="mb-2"
                        placeholder="Cargar nuevo archivo..."
                />
              </b-col>
              <b-col md="3" class="text-right">
                <b-button
                        v-if="file"
                        class="mr-2"
                        variant="primary"
                        @click="uploadSave"

                >
                  <b-spinner v-if="isBusy" small type="grow"></b-spinner>
                  <span v-if="!isBusy">Subir</span>
                  <span class="ml-1" v-if="isBusy">Subiendo</span>
                </b-button>
              </b-col>
              <b-col md="12">
              <p class="mt-2">
                Nombre Del Archivo: <strong>{{ file ? file.name : '' }}</strong>
              </p>
              </b-col>
            </b-row>
            <hr>
            <b-table
              id="my-table-doc"
              ref="table"
              striped
              hover
              small
              :fields="fieldsModal"
              :items="docRectificacion"
              responsive
              class="mb-0"
              show-empty
            >
              <template #cell(nombre_documento)="data">
                <b-link
                  class="font-weight-bold"
                  :href="`${data.item.ruta_documento}`"
                   target="_blank"
                >
                  {{ data.item.nombre_documento }}</b-link>
              </template>
              <template #empty="scope">
                <div class="text-center">
                  No existen resultados
                </div>
              </template>
            </b-table>
          </div>

        </b-modal>
        <b-modal
          id="modal-motivo"
          ok-only
          ok-title="Cerrar"
          centered
          size="lg"
          title="Motivo Rechazo"
        >
          <div>
            <div>
              {{ this.motivoRechazo }}
            </div>
          </div>

        </b-modal>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BFormInput,
  VBModal,
  BLink,
  BFormFile,
  BPagination,
  BSpinner,
  VBTooltip
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ListadoRectificaciones',
  components: {
    BTable,
    BRow,
    BCol,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BFormInput,
    flatPickr,
    BLink,
    BFormFile,
    BPagination,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      showTable: true,
      perPage: 50,
      reportType: 1,
      currentPage: 1,
      page: 1,
      list: [],
      //docRectificacion: [],
      motivoRechazo: [],
      idRectificacion: 0,
      dataResponse: [],
      filter: {
        ficha: null,
        documento: null,
        ordenCompra: null,
        rowsPerPage: 50,
        page: 1,
        fecha: null,
      },
      dataUpload: {
        nombre: null,
        file: null,
        id_rectificacion: 0,
      },
      file: null,
      fields: [
        { key: 'id_rectificacion', label: 'ID Rect.', display: true },
        { key: 'num_ficha', label: 'N° Ficha', display: true },
        { key: 'orden_compra', label: 'O.C', display: true },
        { key: 'tipo_rectificacion', label: 'T. Rect.', display: true },
        { key: 'fecha_registro', label: 'Fecha Rect.', display: true },
        { key: 'curso', label: 'Curso', display: true },
        { key: 'modalidad', label: 'Modalidad', display: true },
        { key: 'empresa', label: 'Empresa', display: true },
        { key: 'otic', label: 'OTIC', display: true },
        { key: 'doc', label: 'DOC', display: true },
        { key: 'estado', label: 'Estado', display: true },
        { key: 'acciones', label: 'Acciones', display: true },
      ],
      fieldsModal: [
        { key: 'tipo_documento' },
        { key: 'nombre_documento', label: 'Documento' },
      ],
      pageOptions: [50, 100, 500, 1000],
      transProps: {
        // Transition name
        name: 'flip-list',
      },
    }
  },
  computed: {
    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default.es,
        dateFormat: 'd-m-Y',
        wrap: true,
      }
    },
    totalRows() {
      return this.$store.state.listadoRectificaciones.rowsTotal
    },
    to() {
      return this.$store.state.listadoRectificaciones.to
    },
    from() {
      return this.$store.state.listadoRectificaciones.from
    },
    listado() {
      return this.$store.state.listadoRectificaciones.listado
    },
    filteredHeaders() {
      return this.fields.filter(h => h.display === true)
    },
    loading() {
      return this.$store.state.listadoRectificaciones.loading
    },
    isBusy() {
       return this.$store.state.listadoRectificaciones.isBusy
    },
    docRectificacion() {
      this.file = null
      return this.$store.state.listadoRectificaciones.docRectificacion
    },
  },
  watch: {

  },
  mounted() {
    this.getList()
  },
  methods: {
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name
      }
      return `${files.length} files selectedDDDDDDDD`
    },
    buscar_listado() {
      this.filter.page = 1
      this.getList()
    },
    onPageChange(page) {
      this.page = page
      this.filter.page = page
      this.getList()
    },
    onChangePerPage() {
      this.page = 1
      this.getList()
    },
    onPerPage() {
      this.filter.rowsPerPage = this.perPage
      this.getList()
    },
    getList() {
      this.$store.dispatch('listadoRectificaciones/getListadoRectificaciones', this.filter)
    },
    getDocRectificaciones(row) {
      //this.docRectificacion = row.list_doc
      this.$store.dispatch('listadoRectificaciones/getListadoDoc', row.list_doc)
      this.idRectificacion = row.id_rectificacion
    },
    getMotivoRechazo(row) {
      this.motivoRechazo = row.motivo.motivo
    },
    getDetalleRectificacion(row) {
      this.$store.commit('listadoRectificaciones/setDetalleRectificacion', row)
      this.$router.push({ name: 'detalle_rectificacion', params: { id: row } })
    },
    uploadSave() {
      this.dataUpload.nombre=this.file.name
      this.dataUpload.file = this.file
      this.dataUpload.id_rectificacion=this.idRectificacion
      this.$store.dispatch('listadoRectificaciones/setUploadSave', this.dataUpload)
    },
},
}

</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.demo-inline-spacing.curso .custom-radio {
  margin-top: 0.5rem !important;
}

.btn-clear {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0px 3px 3px 0px!important;
  color: #6e6b7b;
}
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: 'Browse';
}
</style>
